import {addCustomClass, fadeIn, fadeOut, removeCustomClass} from "../functions/customFunctions";

document.querySelectorAll('.custom-form').forEach((form) => {
  const cameraTrigger = form.querySelector('[data-camera]');
  const galleryTrigger = form.querySelector('[data-gallery]');
  const fileInput = form.querySelector('input[type="file"]');
  const cameraBox = form.querySelector('.camera-box');
  const closeButton = form.querySelector('.camera-box .close');
  const getPhotoButton = form.querySelector('.camera-box .get-photo');
  const cameraFrame = form.querySelector('.camera-box__frame');
  const filePreviews = form.querySelector('.file-preview');
  const clipBtn = form.querySelector('.clip');
  const typeList = form.querySelector('.custom-form__type');
  const accordion = form.querySelector('[data-content]');

  let video, canvas, context;

  function updateAccordionHeight() {
    if (accordion) {
      setTimeout(() => {
        accordion.style.maxHeight = accordion.scrollHeight + "px";
      }, 0);
    }
  }

  function clearPreviews() {
    while (filePreviews.firstChild) {
      filePreviews.removeChild(filePreviews.firstChild);
    }
  }



  clipBtn.addEventListener('click', function (e){
    e.preventDefault()
    addCustomClass(typeList)

    console.log('13')
  })

  document.addEventListener('click', function(e) {
    if (!typeList.contains(e.target) && !clipBtn.contains(e.target)) {
      removeCustomClass(typeList);
    }
  });

  cameraTrigger.addEventListener('click', () => {
    clearPreviews();
    removeCustomClass(filePreviews);
    removeCustomClass(typeList)
    fadeIn(cameraBox, 200, 'flex')
    if (!video) {
      video = document.createElement('video');
      video.autoplay = true;
      cameraFrame.appendChild(video);

      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          video.srcObject = stream;
        }).catch(error => {
        console.error("Ошибка при доступе к камере: ", error);
      });

      canvas = document.createElement('canvas');
      context = canvas.getContext('2d');
    }
  });

  galleryTrigger.addEventListener('click', () => {
    clearPreviews();
    removeCustomClass(filePreviews);
    removeCustomClass(typeList)
    fileInput.click();
  });

  fileInput.addEventListener('change', (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        updateAccordionHeight();
      };
      img.src = e.target.result;

      addCustomClass(filePreviews);
      addCustomClass(img, 'file-preview__img');
      filePreviews.appendChild(img);

      filePreviews.addEventListener('click', () => {
        filePreviews.removeChild(img);
        if (filePreviews.children.length === 0) {
          removeCustomClass(filePreviews);
        }
        fileInput.value = '';
        updateAccordionHeight();
      });
    };

    reader.readAsDataURL(file);
  });

  closeButton.addEventListener('click', () => {

    fadeOut(cameraBox, 200)

    if (video) {
      video.srcObject.getTracks().forEach(track => track.stop());
      cameraFrame.removeChild(video);
      video = null;
    }
  });

  getPhotoButton.addEventListener('click', () => {
    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const img = new Image();
      img.src = canvas.toDataURL('image/png');
      addCustomClass(img, 'file-preview__img');
      addCustomClass(filePreviews);

      clearPreviews();
      filePreviews.appendChild(img);
      updateAccordionHeight();

      filePreviews.addEventListener('click', () => {
        filePreviews.removeChild(img);
        if (filePreviews.children.length === 0) {
          removeCustomClass(filePreviews);
        }
        updateAccordionHeight();
      });

      fadeOut(cameraBox, 200);

      video.srcObject.getTracks().forEach(track => track.stop());
      cameraFrame.removeChild(video);
      video = null;
    }
  });

});
