import {ElementReplacer} from '../functions/replacer'

new ElementReplacer({
  element: document.querySelector('.cart-info'),
  parentDesktop: document.querySelector('.aside-section__aside'),
  parentMobile: document.querySelector('.cart-form__list'),
  breakpoint: 960,
  mobilePlace: "afterbegin",
  desktopPlace: "afterbegin"
});

// 'beforebegin': перед самим элементом targetElement.
// 'afterbegin': внутри элемента targetElement, перед его первым потомком.
// 'beforeend': внутри элемента targetElement, после его последнего потомка.
// 'afterend': после самого элемента targetElement.


