import {fadeIn, fadeOut} from "../functions/customFunctions";
import {closeModal} from "./modals";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('.edit-photo').forEach((parent) => {
    const fileInput = parent.querySelector('.edit-photo__image input[type="file"]');
    const addPhotoButton = parent.querySelector('.add-photo');
    const addImageButton = parent.querySelector('.add-image');
    const removeImageButton = parent.querySelector('.remove-image');
    const saveButton = parent.querySelector('.save');
    const cancelButton = parent.querySelector('.cancel');
    const imagePreview = parent.querySelector('.edit-photo__image img');

    const uploadModalFlag = parent.hasAttribute('data-popup', 'upload-photo');
    const editModalFlag = parent.hasAttribute('data-popup', 'edit-photo');

    let originalImageSrc = imagePreview ? imagePreview.src : '';

    function updateImagePreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (imagePreview) {
          imagePreview.src = e.target.result;
        }

        if (uploadModalFlag) {
          fadeOut(addImageButton, 0);
          fadeIn(cancelButton, 200, 'flex');
          fadeIn(saveButton, 200, 'flex');
        }
      };
      reader.readAsDataURL(file);
    }

    if (addPhotoButton) {
      addPhotoButton.addEventListener('click', () => {
        fileInput.click();
      });
    }

    if (addImageButton) {
      addImageButton.addEventListener('click', () => {
        fileInput.click();
      });
    }

    if (fileInput) {
      fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file) {
          updateImagePreview(file);
        }
      });
    }

    if (removeImageButton) {
      removeImageButton.addEventListener('click', () => {
        if (imagePreview) {
          originalImageSrc = imagePreview.src;
          imagePreview.src = 'img/no_photo.png';
        }
        fileInput.value = '';


        if (editModalFlag) {
          fadeOut(removeImageButton, 0);
          fadeOut(addImageButton, 0);

          fadeIn(saveButton, 200,'flex')
          fadeIn(cancelButton, 200,'flex')
        }

      });
    }

    if (cancelButton) {
      cancelButton.addEventListener('click', () => {
        if (imagePreview) {
          imagePreview.src = originalImageSrc;
        }
        fileInput.value = '';

        if (uploadModalFlag) {
          fadeIn(addImageButton, 200, 'flex');
          fadeOut(cancelButton, 0);
          fadeOut(saveButton, 0);
        }

        if (uploadModalFlag) {
          fadeIn(removeImageButton, 200, 'flex');
        }
      });
    }

    if (saveButton) {
      saveButton.addEventListener('click', () => {
        if (imagePreview) {
          originalImageSrc = imagePreview.src;
        }
        closeModal();
      });
    }
  });
});

