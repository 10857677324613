import vars from '../_vars';
import {elementHeight, elementWidth} from '../functions/customFunctions';

const {header} = vars;

const bar = document.querySelector('.bar');
const mobileMenuCartBottom = document.querySelector('.mobile-menu__bottom');

elementHeight(header, 'header-height');
elementHeight(bar, 'bar-height');
elementHeight(mobileMenuCartBottom, 'cart-mobile-height');
// elementHeight(document.querySelector('.document-card'), 'document-card-height');
// elementWidth(document.querySelector('.step-list'), 'step-list-width');
// elementHeight(document.querySelector('.cart-info'), 'cart-info-height');
