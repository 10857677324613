import {addCustomClass, fadeIn, fadeOut, removeCustomClass, toggleCustomClass} from "../functions/customFunctions";
import {disableScroll} from "../functions/disable-scroll";
import {enableScroll} from "../functions/enable-scroll";
import vars from "../_vars";

const {
  overlay,
  header} = vars;

const headerCatalog = document.querySelector('.header-catalog');
const catalogMenu = document.querySelector('.catalog-menu');
const catalogItems = document.querySelectorAll('.catalog-menu__item');
const catalogBackButton = document.querySelector('.catalog-menu__back');

catalogBackButton.addEventListener('click', function (){
    toggleCustomClass(catalogMenu, 'active');
  })

function removeActiveFromItems() {
  catalogItems.forEach(item => {
    if (item.classList.contains('active')) {
      const subMenu = item.querySelector('.catalog-menu__sub');
      subMenu && fadeOut(subMenu, 0);
    }
    removeCustomClass(item);
  });
}

function toggleMenu() {
  if (headerCatalog.classList.contains('active')) {
    removeCustomClass(headerCatalog);
    fadeOut(catalogMenu, 200);
    enableScroll();
    removeActiveFromItems();
  } else {
    addCustomClass(headerCatalog);
    fadeIn(catalogMenu, 200);
    disableScroll();

    if (catalogItems.length > 0) {
      catalogItems[0].classList.add('active');
      const firstSubMenu = catalogItems[0].querySelector('.catalog-menu__sub');
      firstSubMenu && fadeIn(firstSubMenu, 0);
    }
  }
}

headerCatalog.addEventListener('click', (event) => {
  event.preventDefault();
  event.stopPropagation();
  toggleMenu();
});

document.addEventListener('click', (event) => {
  const target = event.target;
  const clickInsideCatalogMenu = catalogMenu.contains(target) && !catalogMenu.isEqualNode(target);

  if (!clickInsideCatalogMenu && headerCatalog.classList.contains('active')) {
    toggleMenu();
  }
});
// ------------------------------

let isMouseEnterEnabled = true;

function updateMouseEnterState() {
  if (window.matchMedia("(max-width: 960px)").matches) {
    isMouseEnterEnabled = false;
  } else {
    isMouseEnterEnabled = true;
  }
}

updateMouseEnterState();

window.addEventListener('resize', updateMouseEnterState);

catalogItems.forEach(item => {
  item.addEventListener('mouseenter', (event) => {
    if (isMouseEnterEnabled && !item.classList.contains('active')) {
      removeActiveFromItems();
      addCustomClass(item, 'active');

      const subMenu = item.querySelector('.catalog-menu__sub');
      if (subMenu) fadeIn(subMenu, 0);
    }
  });

  item.addEventListener('click', function(e) {
    if (e.target.closest('.catalog-menu__item') && window.innerWidth < 960) {
      if (!e.target.closest('.subcategory-card')) {
        e.preventDefault();
        e.stopPropagation();
      }

      toggleCustomClass(item, 'active');
    }

  });

});



// ------------------------------

document.addEventListener('DOMContentLoaded', () => {
  const headerUser = document.querySelector('.header-user');
  const userList = document.querySelector('.user-list');

  function toggleActive() {
    toggleCustomClass(headerUser);
    toggleCustomClass(userList);
    toggleCustomClass(overlay, 'active-user');
    toggleCustomClass(header);

  }

  headerUser && headerUser.addEventListener('click', (event) => {
    event.stopPropagation();
    event.preventDefault()
    toggleActive();
  });

  if (userList && headerUser ) {``
    document.addEventListener('click', (event) => {
      const target = event.target;
      const clickInsideUserList = userList.contains(target);
      const clickOnHeaderUser = headerUser.contains(target);

      if (!clickInsideUserList && !clickOnHeaderUser && headerUser.classList.contains('active')) {
        toggleActive();
      }
    });
  }

});
