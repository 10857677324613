import datepicker from 'js-datepicker'
import {fadeIn, fadeOut} from "../functions/customFunctions";

document.querySelectorAll('.edit-box').forEach((editBox) => {
  const trigger = editBox.querySelector('.edit-box__trigger');
  const input = editBox.querySelector('input[type="text"]');
  const cancelButton = editBox.querySelector('.main-button');
  const saveButton = editBox.querySelector('.main-button--blue');
  const nav = editBox.querySelector('.edit-box__nav');
  const radios = editBox.querySelectorAll('input[type="radio"]');



  if (editBox.classList.contains('edit-content')) {
    let originalValue = input.value;

    trigger.addEventListener('click', () => {
      editBox.classList.add('active');
      input.removeAttribute('readonly');
      nav.style.maxHeight = nav.scrollHeight + 'px';
    });

    cancelButton.addEventListener('click', () => {
      editBox.classList.remove('active');
      input.setAttribute('readonly', true);
      input.value = originalValue;
      nav.style.maxHeight = '0';
    });

    saveButton.addEventListener('click', () => {
      editBox.classList.remove('active');
      input.setAttribute('readonly', true);
      originalValue = input.value;
      nav.style.maxHeight = '0';
    });
  }


  if (editBox.classList.contains('edit-data')) {
    let picker = null;

    trigger.addEventListener('click', () => {
      if (!picker) {
        picker = datepicker(input, {
          overlayPlaceholder: 'Введiть рiк',
          showAllDates: true,
          days: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', "П'ятниця", 'Субота'],
          customDays: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
          monthsShort: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
          formatter: (input, date, instance) => {
            const value = date && date.toLocaleDateString('uk-UA');
            input.value = value;
          },
          onSelect: (instance, date) => {
            input.value = date && date.toLocaleDateString('uk-UA');
            if (picker) {

              fadeOut(picker.calendarContainer,0)
            }
          },
          onShow: (instance, date) => {
            fadeIn(picker.calendarContainer, 200)
          },
          startDay: 1,
        });
      }
    });
  }


  if (editBox.classList.contains('edit-gender')) {
    let originalValue = input.value;

    trigger.addEventListener('click', () => {
      nav.style.maxHeight = nav.scrollHeight + 'px';

      editBox.classList.add('active');
    });

    radios.forEach(radio => {
      radio.addEventListener('change', () => {
        if (radio.checked) {
          input.value = radio.value;
        }
      });
    });

    cancelButton.addEventListener('click', () => {
      input.value = originalValue;
      radios.forEach(radio => {
        radio.checked = radio.value === originalValue;
      });
      nav.style.maxHeight = '0';
      editBox.classList.remove('active');
    });

    saveButton.addEventListener('click', () => {
      originalValue = input.value;
      nav.style.maxHeight = '0';
      editBox.classList.remove('active');
    });

  }



});
