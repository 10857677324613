import Inputmask from "inputmask";
const inputsTel = document.querySelectorAll(".main-form__input.tel");
const inputsPass = document.querySelectorAll(".sms-pass");


if (inputsTel) {
  inputsTel.forEach(input => {
    if (input) {
      const inputMask = new Inputmask('+38 099 999 99 99');
      input.setAttribute('placeholder', '+38 0')
      inputMask.mask(input);
    }
  });

  inputsPass.forEach(input => {
    if (input) {
      const inputMask = new Inputmask('* * * *');
      inputMask.mask(input);
    }
  });
}


