import Swiper from "../vendor/swiper";
import vars from "../_vars";

import {disableScroll} from "../functions/disable-scroll";
import {enableScroll} from "../functions/enable-scroll";
import {swiperTrackpadSlide} from "../functions/customFunctions";

const {
  quizSliders,
  reviewsSliders,
  servicesSwiper,
  consultantSlider
} = vars;


const bannerSections = document.querySelectorAll('.banner-section');
const categorySlider = document.querySelectorAll('.category-slider');
const mainSlider = document.querySelectorAll('.main-slider');

bannerSections.length && bannerSections.forEach((bannerSection) => {
  const slider = bannerSection.querySelector('.swiper-container');
  const sliderPrev = bannerSection.querySelector('.arrow-button--prev');
  const sliderNext = bannerSection.querySelector('.arrow-button--next');
  const sliderPagination = bannerSection.querySelector('.swiper-pagination');

  new Swiper(slider, {
    watchOverflow        : true,
    watchSlidesVisibility: true,
    slidesPerView        : 1,
    centeredSlides       : true,
    spaceBetween         : 20,
    speed                : 600,

    autoplay: {
      delay               : 5000,
      disableOnInteraction: false,
    },

    navigation: {
      nextEl: sliderNext && sliderNext,
      prevEl: sliderPrev && sliderPrev,
    },

    pagination: {
      el       : sliderPagination,
      clickable: true,
    }
  });

})

categorySlider.length && categorySlider.forEach((slider) => {
  const swiper = slider.querySelector('.swiper-container');
  const sliderPrev = slider.querySelector('.arrow-button--prev');
  const sliderNext = slider.querySelector('.arrow-button--next');

  const mySwiper = new Swiper(swiper, {
    watchOverflow        : true,
    watchSlidesVisibility: true,
    slidesPerView        : 'auto',
    centeredSlides       : false,
    // spaceBetween: 25,
    freeMode      : true,
    slidesPerGroup: 3,
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },
    speed         : 400,

    navigation: {
      nextEl: sliderNext && sliderNext,
      prevEl: sliderPrev && sliderPrev,
    }
  });

  // swiperTrackpadSlide(mySwiper);

})

mainSlider.length && mainSlider.forEach((slider) => {
  let flag = slider.classList.contains('main-slider--offer')
  const swiper = slider.querySelector('.swiper-container');
  const sliderPrev = slider.querySelector('.arrow-button--prev');
  const sliderNext = slider.querySelector('.arrow-button--next');
  const asideSectionParent = slider.closest('.aside-section');

  const hasAsideBreakpoints = {
    0  : {
      spaceBetween : 10,
      slidesPerView: flag ? 'auto' : 2.2,
    },
    481: {
      spaceBetween : 15,
      slidesPerView: flag ? 'auto' : 2.5,
    },
    641: {
      spaceBetween : 15,
      slidesPerView: flag ? 2 : 3,
    },

    961: {
      spaceBetween : 20,
      slidesPerView: flag ? 2 : 3,
    },

    1201: {
      spaceBetween : 20,
      slidesPerView: flag ? 2 : 4,
    }
  }

  const defaultBreakpoints = {
    0  : {
      spaceBetween : 10,
      slidesPerView: flag ? 'auto' : 2.2,
    },
    481: {
      spaceBetween : 15,
      slidesPerView: flag ? 'auto' : 2.5,
    },
    641: {
      spaceBetween : 15,
      slidesPerView: flag ? 'auto' : 3,
    },

    961: {
      spaceBetween : 15,
      slidesPerView: flag ? 'auto' : 4,
    },

    1201: {
      spaceBetween : 20,
      slidesPerView: flag ? 2.5 : 5,
    }
  }

  const mySwiper = new Swiper(swiper, {
    watchOverflow        : true,
    watchSlidesVisibility: true,
    // slidesPerView        : flag ? 2.5 : 5,
    centeredSlides: false,
    spaceBetween  : 20,
    speed         : 500,
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },

    navigation: {
      nextEl: sliderNext && sliderNext,
      prevEl: sliderPrev && sliderPrev,
    },

    breakpoints: asideSectionParent ? hasAsideBreakpoints : defaultBreakpoints,
  });

  // swiperTrackpadSlide(mySwiper);

})




























