import {fadeIn, fadeOut} from "../functions/customFunctions";

const dataHidden = document.querySelectorAll('[data-clip]');

if (dataHidden) {
	dataHidden.forEach(function (item) {
		const btn = item.querySelector('[data-clip-btn]');
		const box = item.querySelector('[data-clip-item]');


		const computedStyle = window.getComputedStyle(box);
		const originalHeight = parseInt(computedStyle.getPropertyValue('max-height'));

		const oldText = btn.textContent;
		btn.addEventListener('click', function (e) {
			e.preventDefault();
			const isOpen = box.getAttribute('data-clip-item') === 'true';


			if (!isOpen) {
				box.style.maxHeight = box.scrollHeight + 'px';
        btn.textContent = btn.getAttribute('data-clip-btn');
			} else {
				box.style.maxHeight = originalHeight + 'px';
        btn.textContent = oldText;
			}

			box.setAttribute('data-clip-item', !isOpen);
		});

		box.style.transition = 'max-height 0.4s linear';
	});
}

document.addEventListener("DOMContentLoaded", function() {
  const checkbox = document.querySelector('.bonus-cart-widget__switcher input[type="checkbox"]');
  const bonuses = document.querySelector('li.bonuses');
  function toggleBonusesVisibility() {
    if (checkbox.checked) {
      fadeIn(bonuses, 400, 'flex');
    } else {
      fadeOut(bonuses, 400);
    }
  }

  if (checkbox && bonuses) {
    toggleBonusesVisibility();
    checkbox.addEventListener('change', toggleBonusesVisibility);
  }
});

document.addEventListener("DOMContentLoaded", function() {
  const editButtons = document.querySelectorAll('.address-tabs__field .input-text__edit');
  const inputs = document.querySelectorAll('.address-tabs__field input[type="text"]');

  editButtons.forEach(button => {
    button.addEventListener('click', function(event) {
      const parentField = button.closest('.address-tabs__field');
      if (parentField) {
        const input = parentField.querySelector('input[type="text"]');
        if (input && event.target === button) {
          input.removeAttribute('readonly');
          input.value = '';
          input.focus();
        }
      }
    });
  });


  inputs.forEach(input => {
    input.addEventListener('click', function() {
      this.removeAttribute('readonly');
      this.value = '';
      this.focus();
    });
  });
});


let isButtonVisible = false;

window.addEventListener('scroll', () => {
  const scrollToTopButton = document.getElementById('scrollToTopButton');
  const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

  if (currentScrollPosition > 1500 && !isButtonVisible) {
    fadeIn(scrollToTopButton, 200, 'flex');
    isButtonVisible = true;
  } else if (currentScrollPosition <= 1500 && isButtonVisible) {
    fadeOut(scrollToTopButton, 200);
    isButtonVisible = false;
  }
});

document.getElementById('scrollToTopButton').addEventListener('click', () => {
  window.scrollTo({top: 0, behavior: 'smooth'});
});

