import {addCustomClass, removeCustomClass} from "../functions/customFunctions";

document.addEventListener("DOMContentLoaded", function() {
  const stars = document.querySelectorAll('.stars-rating__icon');
  let currentRating = 0;
  let isRated = false;

  function updateRating(index, isHover = false) {
    stars.forEach((star, i) => {
      if (i <= index) {
        addCustomClass(star, 'active');
      } else {
        removeCustomClass(star, 'active');
      }
    });

    if (!isHover) {
      currentRating = index + 1;
      isRated = true;
    }
  }

  stars.forEach((star, index) => {
    star.addEventListener('click', () => {
      if (currentRating === index + 1 && isRated) {
        removeCustomClass(star, 'active');
        currentRating = 0;
        isRated = false;
      } else {
        updateRating(index);
      }
    });

    star.addEventListener('mouseover', () => {
      if (!isRated) {
        updateRating(index, true);
      }
    });

    star.addEventListener('mouseout', () => {
      if (!isRated) {
        stars.forEach((s, i) => {
          removeCustomClass(s, 'active');
        });
      }
    });
  });
});

