import {addCustomClass, removeCustomClass, toggleCustomClass} from "../functions/customFunctions";

document.addEventListener('DOMContentLoaded', function() {
  const forms = document.querySelectorAll('.add-form');

  forms.forEach(form => {
    const quantityInput = form.querySelector('.product-quantity');
    const quantityPlaceholder = form.querySelector('.add-form__label').getAttribute('data-placeholder');
    quantityInput.value = 0;

    if (!form.querySelector('.quantity-text')) {
      quantityInput.insertAdjacentHTML('afterend', `<span class="quantity-text">${quantityPlaceholder}</span>`);
    }

    const decreaseBtn = form.querySelector('.decrease-btn');
    const increaseBtn = form.querySelector('.increase-btn');

    decreaseBtn.addEventListener('click', function() {
      removeCustomClass(increaseBtn, 'active');
      addCustomClass(decreaseBtn, 'active');

      let currentValue = parseInt(quantityInput.value, 10);
      if (currentValue > 0) {
        quantityInput.value = currentValue - 1;
      }

      if (currentValue - 1 === 0 && form.classList.contains('active')) {
        removeCustomClass(form, 'active');
      }
    });

    increaseBtn.addEventListener('click', function() {
      addCustomClass(increaseBtn, 'active');
      removeCustomClass(decreaseBtn, 'active');

      let currentValue = parseInt(quantityInput.value, 10);
      quantityInput.value = currentValue + 1;

      if (!form.classList.contains('active')) {
        addCustomClass(form, 'active');
      }
    });

    decreaseBtn.addEventListener('mouseenter', function() {
      removeCustomClass(increaseBtn, 'active');
      addCustomClass(decreaseBtn, 'active');
    });

    decreaseBtn.addEventListener('mouseleave', function() {
      addCustomClass(increaseBtn, 'active');
      removeCustomClass(decreaseBtn, 'active');
    });
  });

  // Обработчик событий для кнопок "Нравится"
  const likeBtns = document.querySelectorAll('.product-card__favorite');

  likeBtns.forEach((btn) => {
    btn.addEventListener('click', function() {
      toggleCustomClass(btn, 'active');
    });
  });
});

