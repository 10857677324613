import {
  removeClassInArray,
  addCustomClass,
} from "../functions/customFunctions";
import vars from "../_vars";

const addressTab = document.querySelectorAll('.address-tabs');
const deliveryTabs = document.querySelectorAll('.delivery-type');
const typeRender = document.querySelectorAll('.render-type');

// --------------- tabs custom function --------------- //
const tabsFunction = function (
  tabsDataInitArray,
  tabsNavAttr,
  tabsContentAttr,
  active = "active"
) {
  tabsDataInitArray &&
  tabsDataInitArray.forEach((tabParent) => {
    if (tabParent) {
      const tabNav = [...tabParent.querySelectorAll(`[${tabsNavAttr}]`)];
      const tabContent = [
        ...tabParent.querySelectorAll(`[${tabsContentAttr}]`),
      ];

      tabNav.map((nav) => {
        nav.addEventListener("click", (e) => {
          e.preventDefault();
          const activeTabAttr = e.target.getAttribute(`${tabsNavAttr}`);
          removeClassInArray(tabNav, active);
          removeClassInArray(tabContent, active);
          addCustomClass(
            tabParent.querySelector(`[${tabsNavAttr}="${activeTabAttr}"]`),
            active
          );
          addCustomClass(
            tabParent.querySelector(
              `[${tabsContentAttr}="${activeTabAttr}"]`
            ),
            active
          );
        });
      });
    }
  });
};

tabsFunction(addressTab, "data-tab", "data-tab-content");
tabsFunction(deliveryTabs, "data-delivery", "data-delivery-content");
tabsFunction(typeRender, "data-render", "data-render-content");

document.querySelectorAll('[data-delivery]').forEach(function (btn){
  btn.addEventListener('click',function (e){
    e.preventDefault();
    removeClassInArray(document.querySelectorAll('[data-delivery-type]'))
    const dataName = btn.dataset.delivery;

    addCustomClass(document.querySelector(`[data-delivery-type="${dataName}"]`))

  })
})
