import './components/dinamicHeight';
import './components/sliders';
import './components/mobileMenu';
import './components/select';
import './components/form';
import './components/search';
import './components/modals';
import './components/accordion';
import './components/range';
import './components/add-form';
import './components/global';
import './components/cart';
import './components/replaceEl';
import './components/tabs';
import './components/inputTel';
import './components/checkbox-select';
import './components/stars-rating';
// import './components/mapInit';
import './components/chat-form';
import './components/edit-form';
import './components/add-photo';
import './components/desktop-menu';




